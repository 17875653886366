import React, { useRef } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { Button, Stack } from '@mui/material';
import { downloadFile } from 'utils/downloadFile';
import colors from 'constants/colors';

interface ClimateChoiceIconProps extends SvgIconProps {
  status?: 'GOLD' | 'SILVER' | 'BRONZE';
  date?: string;
}

const ClimateChoiceIcon: React.FC<ClimateChoiceIconProps> = ({
  status = 'SILVER',
  date = 'DD/MM/YYYY',
  ...props
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  const handleDownload = () => {
    if (svgRef.current) {
      const svgElement = svgRef.current;

      // Get the bounding box of the SVG
      const { width, height } = svgElement.getBoundingClientRect();

      // Create a new canvas element
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;

      const context = canvas.getContext('2d');

      // Create a new image
      const img = new Image();

      // Convert the SVG into a data URL
      const svgData = new XMLSerializer().serializeToString(svgElement);
      const svgBlob = new Blob([svgData], {
        type: 'image/svg+xml;charset=utf-8',
      });
      const url = URL.createObjectURL(svgBlob);

      img.onload = () => {
        // Draw the image on the canvas
        context?.drawImage(img, 0, 0, width, height);

        // Convert the canvas to a PNG data URL
        const pngDataUrl = canvas.toDataURL('image/png');

        // Create a download link
        const link = document.createElement('a');
        link.href = pngDataUrl;
        link.download = 'climate_choice_icon.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Clean up the URL object
        URL.revokeObjectURL(url);
      };

      // Set the image source to the URL of the SVG
      img.src = url;
    }
  };

  const statusColors = colors[status.toLowerCase() as keyof typeof colors] as {
    start: string;
    end: string;
    borderLight: string;
    borderDark: string;
  };

  return (
    <Stack direction="column" alignItems="center" spacing={2}>
      {/* The Climate Choice Icon */}
      <SvgIcon ref={svgRef} component={'svg'} viewBox="0 0 104 104" {...props}>
        <defs>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="metalGradient"
            gradientTransform="rotate(45)"
          >
            <stop offset="0%" stopColor={statusColors.borderDark} />
            <stop offset="30%" stopColor={statusColors.start} />
            <stop offset="50%" stopColor={statusColors.borderDark} />
            <stop offset="65%" stopColor={statusColors.end} />
            <stop offset="75%" stopColor={statusColors.start} />
            <stop offset="80%" stopColor={statusColors.end} />
            <stop offset="90%" stopColor={statusColors.borderDark} />
            <stop offset="100%" stopColor={statusColors.end} />
          </linearGradient>

          <path
            id="circlePath"
            d="M 52,52 m -40,0 a 40,40 0 1,1 80,0 a 40,40 0 1,1 -80,0"
          />
          <path
            id="circlePathReversed"
            d="M 52,52 m 47,0 a 47,47 0 1,0 -94,0 a 47,47 0 1,0 94,0"
          />
        </defs>
        <circle cx="52" cy="52" r="51" opacity={1} fill={'white'} />

        <circle
          cx="52"
          cy="52"
          r="51"
          opacity={0.3}
          stroke={statusColors.borderDark}
          strokeWidth="0.5"
          fill={statusColors.end}
        />
        <circle
          cx="52"
          cy="52"
          r="35"
          stroke="url(#metalGradient)"
          strokeWidth="3"
          fill="url(#metalGradient)"
        />
        <circle cx="94" cy="40" r="2" fill={statusColors.borderDark} />
        <circle cx="8" cy="50" r="2" fill={statusColors.borderDark} />
        <circle cx="43" cy="95" r="2" fill={statusColors.borderDark} />
        <g transform="translate(30, 30) scale(0.22)">
          <path
            d="M24.2836 119.778C13.4546 77.9251 38.6041 35.2181 80.4568 24.3891C121.334 13.8125 163.025 37.5564 175.036 77.6623C181.408 74.3911 188.204 71.7207 195.368 69.7424C179.374 19.4938 126.68 -10.0513 75.0051 3.31918C21.5159 17.1591 -10.6262 71.7402 3.21371 125.229C16.6469 177.147 68.4617 208.953 120.406 198.122C118.608 190.908 117.594 183.678 117.311 176.524C76.2252 185.828 34.9091 160.844 24.2836 119.778Z"
            fill="white"
          />
          <path
            d="M140.429 167.26C139.951 175.004 140.555 182.933 142.355 190.851C180.047 173.325 203.089 133.919 199.822 92.4317C192.044 94.7539 184.842 98.1253 178.331 102.349C177.602 128.966 163.231 153.555 140.429 167.26Z"
            fill="white"
          />
        </g>

        <text
          fontSize="10"
          fill={statusColors.borderDark}
          fontFamily="Mitra Mono, sans-serif"
        >
          <textPath
            // fontWeight={'bold'}
            href="#circlePath"
            startOffset="23%"
            textAnchor="middle"
            letterSpacing={0.5}
          >
            CLIMATE CHOICE
          </textPath>
        </text>

        <text
          fontSize="10"
          fill={statusColors.borderDark}
          fontFamily="Mitra Mono, sans-serif"
        >
          <textPath
            // fontWeight={'bold'}
            href="#circlePathReversed"
            startOffset="88%"
            letterSpacing={1.5}
            textAnchor="middle"
          >
            {date}
          </textPath>
        </text>

        <text
          fontSize="10"
          fill={statusColors.borderDark}
          fontFamily="Mitra Mono, sans-serif"
        >
          <textPath
            // fontWeight={'bold'}
            letterSpacing={1.5}
            href="#circlePathReversed"
            startOffset="61%"
            textAnchor="middle"
          >
            {status}
          </textPath>
        </text>
      </SvgIcon>

      {/* Download Button */}
      <Button variant="contained" color="primary" onClick={handleDownload}>
        Download File
      </Button>
    </Stack>
  );
};

export default ClimateChoiceIcon;
