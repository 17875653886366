import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import Container from 'components/common/Container';

import Stack from 'components/common/Stack';
import colors from 'constants/colors';
import { useTranslation } from 'react-i18next';
import StatusLabel from 'components/icons/StatusLabel';
import Box from 'components/common/Box';
import TccInputTextField from 'components/common/TccInputTextField';
import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const formatDate = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
};

function CompanyLabelAndCert() {
  const { t } = useTranslation();
  const [date, setDate] = useState(formatDate(new Date()));
  const [status, setStatus] = useState('GOLD');

  return (
    <Container disableGutters pageContainer maxWidth="md">
      <Stack spacing={2}>
        <Typography component="span" variant="h4" pb={2}>
          {'Status Label'}
        </Typography>
        <Stack spacing={2}>
          <Stack direction={'row'} spacing={2}>
            <Box>
              <FormControl fullWidth>
                <InputLabel>{t('Status')}</InputLabel>
                <Select
                  value={status}
                  onChange={(event) => {
                    setStatus(event.target.value);
                  }}
                  label={t('Status')}
                >
                  <MenuItem value="GOLD">{t('Gold')}</MenuItem>
                  <MenuItem value="SILVER">{t('Silver')}</MenuItem>
                  <MenuItem value="BRONZE">{t('Bronze')}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <TccInputTextField
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </Box>
          </Stack>
          <Box fontSize={'140px'} width={'200px'}>
            <StatusLabel
              fontSize="inherit"
              date={date}
              status={status as 'GOLD' | 'SILVER' | 'BRONZE'}
            />
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
}

export default CompanyLabelAndCert;
