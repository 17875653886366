const colors = {
  primary500: '#6AD1BC',
  primary400: '#78D5C2',
  primary300: '#86DAC9',
  primary100: '#EAF9F6',
  secondary500: '#113192',
  secondary400: '#28459C',
  secondary300: '#3E59A7',
  secondary100: '#E8EBF6',
  onSurface200: '#585757',
  onSurface100: '#1C1C1C',
  neutral800: '#131D29',
  neutral700: '#2D3F53',
  neutral600: '#4E6883',
  neutral500: '#6F89A4',
  neutral400: '#98A8B9',
  neutral200: '#E6EBEF',
  neutral100: '#F9F9F9',
  warning100: '#FFF3CC',
  warning500: '#FFAB00',
  tertiary100: '#ffccd8',
  tertiary500: '#EB6E80',
  tertiary600: '#E15555',
  tertiary800: '#964652',
  white: '#FFFFFF',
  error: '#E15555',
  //
  brandYellow: '#FBFB62',
  gold: {
    start: '#FFD700',
    end: '#FFA500',
    borderLight: '#FFF8DC',
    borderDark: '#B8860B',
  },
  silver: {
    start: '#efefef',
    end: '#D3D3D3',
    borderLight: '#F0F0F0',
    borderDark: '#A0A0A0',
  },
  bronze: {
    start: '#F0C27B', // A lighter and warmer bronze start color
    end: '#D4A05D', // A softer, more golden end color
    borderLight: '#FFE4C4', // A very light, warm beige for a softer border highlight
    borderDark: '#A0522D', // A richer, warm brown for a subtle border shadow
  },
};

export default colors;
